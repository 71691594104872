import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LabelMakerComponents from './label-maker/label-maker-components';

const ImageWithLoader = ({
  src, alt, title, width,
}) => {
  let loaded;
  let setLoaded;

  if (typeof window === 'undefined') {
    // Load image without loader in SSR
    [loaded, setLoaded] = useState(true);
  } else {
    [loaded, setLoaded] = useState(false);
  }

  return (
    <>
      {loaded ? null : (
        <img
          src={LabelMakerComponents.image.loading}
          alt="Loading"
        />
      )}
      <img
        style={loaded ? {} : { display: 'none' }}
        src={src}
        alt={alt}
        title={title}
        width={width}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

ImageWithLoader.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
};

export default ImageWithLoader;

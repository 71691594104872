import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import JSONData from '../../conf/label-maker.json';
import { setImage as setImageAction } from '../../state/actions';
import { getLabelImage } from '../../state/selectors';
import RadioButton from '../radio-button';
import LabelMakerComponents from './label-maker-components';
import ImageWithLoader from '../image-with-loader';

const LabelMakerImage = ({
  intl, currentImage, setImage, imageCategories,
}) => {
  function setRadio(event) {
    setImage(event.target.value);
  }

  const categories = imageCategories || Object.keys(JSONData.images);
  const images = [];
  categories.forEach((category) => {
    images.push(
      <div className="column is-full" key={category}>
        <h4 className="is-size-4 has-text-grey-dark has-text-weight-medium m-t-lg p-l-xs has-text-left">
          <FormattedMessage id={`label-maker.image.categories.${category}`} />
        </h4>
      </div>,
    );
    JSONData.images[category].map((img) => images.push(
      <div className="column is-4-mobile is-3-tablet is-2-widescreen" key={img}>
        <RadioButton name="image" className="m-xs" value={img} checked={img === currentImage}>
          <ImageWithLoader
            width="80px"
            src={LabelMakerComponents.image[img]}
            alt={intl.formatMessage({ id: `label-maker.images.titles.${img}` })}
            title={intl.formatMessage({ id: `label-maker.images.titles.${img}` })}
          />
        </RadioButton>
      </div>,
    ));
  });

  return (
    <div>
      <div className="notification is-info is-light has-icon">
        <span className="icon">
          <i className="fas fa-info-circle fa-lg" />
        </span>
        <span className="p-l-lg">
          <FormattedMessage id="label-maker.image.help" />
        </span>
      </div>
      <div className="control has-text-centered" onChange={setRadio.bind(this)}>
        <div className="columns is-mobile is-multiline is-gapless">
          {images}
        </div>
      </div>
    </div>
  );
};

LabelMakerImage.propTypes = {
  imageCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  intl: PropTypes.object,
  currentImage: PropTypes.string,
  setImage: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentImage: getLabelImage(state),
});

const mapDispatchToProps = {
  setImage: setImageAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LabelMakerImage));
